import React from "react"
import Layout from "../components/layout"
import {Link} from "gatsby"
import WaveSvg from "../assets/images/Wave.svg"
import {isValidContentfulImage, isValidSectionField} from "../common/utils/CommonUtils"
import SEO from "../components/seo";
import BreadCrumbs from "../components/bread-crumbs";
import "./all-landing-ui.scss";


const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    },
    {
        title: "Get Started",
        link: "/get-started/",
    },
    {
        title: "How To Guides",
        link: "/how-to-guides/",
    }

]

const navigateToCourseDetail = (slug) => {
    window.location.assign(
        `/how-to-guides/mental-health-and-substance-use/${slug}/`);
};

export default function (props) {
    const courses = props.pageContext.data;

    return (
        <Layout>
            <SEO title="Our Courses"/>
            <BreadCrumbs currentPage={"All Course"}  breadCrumbsList={breadCrumbsList}/>
            <div className="all-common-main">
                <div className="common-wrap-bg"
                     style={{backgroundImage: `url(${WaveSvg})`}}>
                    <h2 className="all-common-main-heading">Self Directed
                        Courses</h2>
                    <p className="all-common-main-para">
                        Confidant’s Self-Directed Courses are designed for
                        people who are looking for resources or information,
                        either for themselves or a loved one. Courses are
                        delivered through conversations with our chatbot,
                        Alfie, right in the Confidant app. You can start a
                        course out of curiosity or at the recommendation
                        of your provider, and you can complete them at your own
                        pace. Check them out and start learning!
                    </p>
                    <div className="common-wrap">
                        <div className="common-list">
                            {
                                courses && courses.map((course) => {
                                    return (
                                        <div className="single-card">
                                            {
                                                isValidContentfulImage(
                                                    course.courseImage) && (
                                                    <img className="card-img"
                                                         src={'https:'
                                                         + course.courseImage.file.url}
                                                         onClick={() => navigateToCourseDetail(
                                                             course.slug)}
                                                         alt="Card Visual"/>
                                                )
                                            }
                                            <div
                                                className="card-content-section">
                                                {
                                                    isValidSectionField(course,
                                                        "courseName") &&
                                                    <h3 className="card-title"
                                                        onClick={() => navigateToCourseDetail(
                                                            course.slug)}>{course.courseName}</h3>
                                                }
                                                <div className="card-info-wrap">
                                                    {
                                                        isValidSectionField(
                                                            course,
                                                            "subheading") &&
                                                        <p className="card-info">{course.subheading}</p>
                                                    }
                                                </div>
                                                {
                                                    isValidSectionField(course,
                                                        "description") &&
                                                    <p className="card-desc">
                                                        {course.description}
                                                    </p>
                                                }
                                                <Link className="outline-btn"
                                                      to={`/how-to-guides/mental-health-and-substance-use/${course.slug}/`}
                                                >Learn More</Link>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="after-common-wrap">
                <div className="after-common-inner">
                    <h4 className="after-common-head">Confidant’s Expert Curated
                        Content</h4>
                    <p className="after-common-para">
                        Knowledge is power, but it’s extremely difficult to find
                        trustworthy information in this day and age, especially
                        related to drugs and alcohol.
                        At Confidant, we’re here to set the record straight. Our
                        team of experts has put together a library of content to
                        help answer all of your questions
                        and provide you with the information you need. Whether
                        you’re looking for a how-to guide to jump starting a
                        mediation practice, tips on how to repair
                        a damaged relationship, or information about the
                        medication you’ve been prescribed, we’ve got it.
                        <br/><br/>
                        The Conversational Courses are a way to package content
                        that other Confidant users found helpful together, and
                        to make it more engaging by chatting with Alfie, our
                        chatbot. Courses are grouped around specific
                        topics and include answers to common questions or
                        information that we think is relevant. You can go
                        through them at your own pace and come back to
                        the contents later on. If you ever have questions, our
                        providers and coaches are available to help!

                    </p>
                </div>
            </div>
        </Layout>
    )
}

